import React from 'react';
import './../style/IAM_Bold.css';
import { motion } from 'framer-motion';
import { slideAnimation, springAnimation } from '../util/JsAnimations';

function IAM_Bold() {
    return (
        <section id="IAM_Bold">
            <div id="IAM_Bold_overlay">
                <div id="subtext">
                    <motion.div initial={slideAnimation('left').initial} whileInView={slideAnimation('left').whileInView} exit={slideAnimation('left').exit} className='subtext--text'>Take Risks</motion.div>
                    <motion.div initial={slideAnimation('right').initial} whileInView={slideAnimation('right').whileInView} exit={slideAnimation('right').exit} className='subtext--text'>Embrace Change</motion.div>
                </div>
                <div id="IAM_Bold_text">
                    <span>
                        <motion.div initial={slideAnimation('left').initial} whileInView={slideAnimation('left').whileInView} exit={slideAnimation('left').exit}>I AM </motion.div>
                        <motion.div initial={springAnimation.initial} whileInView={springAnimation.whileInView} transition={{delay:0.4}} >BOLD</motion.div>
                    </span>
                </div>
            </div>
        </section>
    )
}

export default IAM_Bold