import React from 'react';
import './Pointer.css';

function Pointer(props) {

    return (
        <a className='pointer' href={'#' + props.anchor}>
            <img src="/assets/arrow-down.png" alt="arrow-down" />
        </a>
    )
}

export default Pointer