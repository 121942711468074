import React from 'react';
import './../style/experienceStory.css';
import { motion } from 'framer-motion';
import { slideAnimation} from './../util/JsAnimations';

function Experience_Story(props) {

    if(props.role === undefined) throw new Error('Role is not defined');
    if(props.company === undefined) throw new Error('Company is not defined');
    if(props.location === undefined) throw new Error('Location is not defined');
    if(props.duration === undefined) throw new Error('Duration is not defined');
    if(props.goBack === undefined) throw new Error('goBack is not defined');
    
    // Create about section
    let about = <></>
    if(props.role == "Full Stack Developer" && props.company == "Duende Digital"){
        about= <>
            <p>
                In my previous role, I was a skilled software developer responsible for building cutting-edge applications for <strong>mobile, web, and desktop</strong> platforms. Utilizing an array of programming languages, frameworks, and tools, I create intuitive and <em>user-friendly</em> solutions that cater to our clients' specific needs. By adopting a <strong>result-oriented approach</strong>, <u>I consistently achieve outstanding outcomes</u>, delivering applications that not only meet but <strong>exceed</strong> our clients' expectations.
            </p>
            <p>
                Over my time spent at <strong>Duende Digital</strong>, I have:
            </p>

            <ul>
                <li>
                    Successfully participated in building, maintaining and optimizing 100% of the mobile applications, websites/web applications, and APIs created since November of 2021, in addition to participating in maintaining legacy projects.
                </li>
                <li>
                    Increased overall productivity on backend and frontend development by at least 50%.
                </li>
                <li>
                    Efficiently planned and executed projects, through rigorous research and documentation, reducing time-to-market by at least 20% and ensuring smooth development processes.
                </li>
                <li>
                    Fostered a supportive work environment, through a proactive approach to collaborating with team members, where we collectively strive for excellence and share knowledge to drive positive results.
                </li>
                <li>
                    Embraced modern development practices, such as Continuous Integration and Continuous Delivery (CI/CD) principles, allowing me to manage project lifecycles effectively, contributing to a 90% increase in reliable software deployments on a frequent basis.
                </li>
                <li>
                    Utilized unit testing to guarantee an 80% increase code quality, a 60% decrease in issues and bugs, thus enhancing the overall stability of the delivered products.
                </li>
            </ul>

            <p>
                My <strong>passion for innovation</strong>, coupled with a focus on delivering exceptional results, consistently contributes to the success of our projects and the satisfaction of our clients.
            </p>
        </>
    } else if(props.role == "Integration Developer" && props.company == "Doxim"){
        about = <>
            <p>
                As a Security Integration Developer, my role is to provide the following services:

                <ul>
                    <li>System Integration: Design, develop, and implement security systems integration with existing IT infrastructure.</li>
                    <li>Threat Analysis: Conduct thorough assessments of potential security threats and vulnerabilities.</li>
                    <li>Solution Development: Develop and customize security solutions tailored to meet specific organizational needs.</li>
                    <li>Compliance Assurance: Ensure compliance with industry standards and regulatory requirements.</li>
                    <li>Collaboration: Work closely with cross-functional teams to align security measures with business objectives.</li>
                    <li>Documentation: Create detailed documentation for security systems, processes, and integration protocols.</li>
                    <li>Training: Provide training and support to internal teams on security best practices and system use.</li>
                    <li>Continuous Improvement: Stay updated with the latest security trends and technologies to continuously improve security posture.</li>
                </ul>
            </p>
        </>
    } else if(props.role == "Learning Specialist" && props.company == "2U"){
        about = <>
            <p>I <em>mentor, assist and coach</em> learners via practical examples, lessons, <strong>code reviews, constructive criticism and demonstration</strong>.</p>

            <p><u>I guide students through their journey</u> by providing them with industry examples and resources that they may use to become ready for the working world when the graduate. What I enjoy most about this role is that it allows me to spread the knowledge and joy of software engineering as well as all hone in on my craft by teaching others.</p>

            <p>I have achieved the following in my time here at 2U:</p>

            <ul>
                <li>Increased the assignments graded per shift by 10%</li>
                <li>Increased the number of student reviews on my shift by at least 60%</li>
                <li>Successfully multi-tasked between dealing directly with students to solve problems and grading student assignments</li>
                <li>Successfully tackled over 200 student student questions with a 100% satisfaction rate</li>
            </ul>
        </>
    }

    return (
        <motion.div id='experience_story'>
          <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
            <div id="story_content">
                <div id="top">
                    <div id="top_back">
                        <div id="top_back_btn" onClick={props.goBack}>{"<<"} Back</div>
                    </div>

                    <motion.h3 initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} id="top_company">{props.company}</motion.h3>
                    <motion.h2 initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} id="top_role">{props.role}</motion.h2>
                    <motion.div initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} id="top_location">{props.location}</motion.div>
                    <motion.div initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} id="top_duration">{props.duration}</motion.div>
                </div>
                <div id="bottom">
                    <motion.div initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} id="bottom_about">
                        {about}
                    </motion.div>
                </div>
            </div>
        </motion.div>
    )
}

export default Experience_Story