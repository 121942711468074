import React from 'react';
import './index.css';

function ShopItem(props) {

    const motion = props.motion;
    const animation = props.animation;

    return (
        <motion.div className={"shop-item "+props.color+" "+props.font_style} data-price={props.price}
            initial={animation.initial} whileInView={animation.whileInView} exit={animation.exit}
            onClick={()=>props.onclick(props.price)}
        >
            <div className="shop-item_top">
                <div className="shop-item--heading">{props.title}</div>
                <div className="shop-item--sub-heading">{props.type}</div>
            </div>
            <div className="shop-item_bottom">
                {/* Display grid
                    delivery delivery delivery View
                    outcome outcome outcome View
                */}

                <div className="shop-item--delivery">{props.delivery}-Day Delivery</div>
                <div className="shop-item--outcome">{props.outcome}</div>
                <a href="#" className="shop-item--view">
                    <img src="/assets/icons/arrow_right.svg" alt="View" />
                </a>
            </div>
        </motion.div>
    )
}

export default ShopItem