import React from 'react';
import './NavButton.css';

function NavButton(props) {
  return (
    <a className={'nav_button'} href={props.url ? props.url : "#"}>
      <span>{props.name}</span>
      <div className="nav_button--hover"></div>
    </a>
  )
}

export default NavButton