import React, { useState } from 'react'
import './../style/Education.css';
import Education_Overview from '../islands/Education_Overview';
import Education_Story from '../islands/Education_Story';

function Education() {

    const [viewStory, setViewStory] = useState(false);
    const [institute, setInstitute] = useState("");
    const [degree, setDegree] = useState("");
    const [minor, setMinor] = useState("");
    const [date, setDate] = useState("");
    const [description, setDescription] = useState("");

    const handleEducationClick = (event) => {

        try{
            // Extract all necessary data first
            if (event.target.getAttribute("data-institute")) setInstitute(event.target.getAttribute("data-institute"));
            if (event.target.getAttribute("data-degree")) setDegree(event.target.getAttribute("data-degree"));
            if (event.target.getAttribute("data-minor")) setMinor(event.target.getAttribute("data-minor"));
            if (event.target.getAttribute("data-date")) setDate(event.target.getAttribute("data-date"));
        }catch(err){
            alert(err);
        }
        
        if(event.target.getAttribute("data-institute") === "Richfield") {
            setDescription(
                <>
                    <p>Today IT goes beyond the design, and development of computer systems (hardware and software) and networks, which were historically used for obtaining, processing, and distributing data. This course has taught me the fundamentals of Software Engineering and has given me a deeper understanding of network engineering, software engineering and hardware.</p>

                    <p>I have completed my degree with distinctions in all 12 modules of my final year. My academic transcript is available on request.</p>
                </>
            )
        }else if(event.target.getAttribute("data-institute") === "Varsity College Durban North") {
            setDescription(
                <>
                    <p>I studied the IIE Bachelor of Computer and Information Sciences in Application Development for one year. During this time, I gained foundational knowledge in IT principles, programming with Java, system architecture, and network engineering. The course covered key areas such as mobile and web development, database design, troubleshooting, and project management.</p>

                    <p>This experience equipped me with essential skills in application development and a solid understanding of IT concepts. Although I did not complete the degree, the knowledge and skills I acquired have provided a strong base for pursuing a career in software development and IT.</p>
                </>
            )
        }

        // Then set the state of the viewStory variable to true
        setViewStory(true);
    }

    return (
        <section id="education">
            {
                viewStory?
                <Education_Story  
                    institute={institute}
                    degree={degree}
                    minor={minor}
                    date={date}
                    description={description}
                    goBack={() => setViewStory(false)}
                />
                :
                <Education_Overview handleEducationClick={handleEducationClick} />
            }
        </section>
    );
}

export default Education