import React from 'react';
import { motion } from 'framer-motion';
import {springAnimation} from './../../util/JsAnimations';
import './PersonalityTrait.css';

function PersonalityTrait(props) {

  if(props.delay === undefined) throw new Error("PersonalityTrait: delay prop is required");
  if(props.text === undefined) throw new Error("PersonalityTrait: text prop is required");

  return (
    <motion.div title={props.other ? props.other.title : props.text} className='personalityTrait' initial={springAnimation.initial} whileInView={springAnimation.whileInView} transition={{...springAnimation,
      delay: props.delay }}
    >{props.text}</motion.div>
  );
}

export default PersonalityTrait