import Hero from './sections/Hero';
import About from './sections/About';
import Nav from './islands/Nav'
import IAM_Bold from './sections/IAM_Bold';
import IAM_Creative from './sections/IAM_Creative';
import IAM_Educated from './sections/IAM_Educated';
import Education from './sections/Education';
import Experience from './sections/Experience';
import WhatIDo from './sections/WhatIDo';
import Skills from './sections/Skills';
import ThingsIDo from './sections/ThingsIDo';
import Projects from './sections/Projects';
import WorthIt from './sections/WorthIt';
// import Recommendations from './sections/Recommendations';
import Stars from './sections/Stars';
import Contact from './sections/Contact';
import Footer from './components/Footer'

function App() {
  return (
    <>
      <Hero />
      <Nav />
      <About />
      <IAM_Bold />
      <IAM_Creative />
      <IAM_Educated />
      <Education />
      <Experience />
      <WhatIDo />
      <Skills />
      <ThingsIDo />
      {/* <Projects /> */}
      <WorthIt />
      <Stars />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
