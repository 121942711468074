import React from 'react';
import './../style/IAM_Creative.css';
import { motion } from 'framer-motion';
import { slideAnimation } from '../util/JsAnimations';

function IAM_Creative() {
    return (
        <motion.section id="IAM_Creative" initial={{opacity:0,transition:{duration:1}}} whileInView={{opacity:1,transition:{duration:1}}} exit={{opacity:0,transition:{duration:1}}} >
            <div id="IAM_Creative_overlay">
                <div id="IAM_Creative_text">
                    <span>
                        <motion.div initial={slideAnimation('down').initial} whileInView={slideAnimation('down').whileInView} exit={slideAnimation('down').exit} transition={{delay:0.4}}>I AM </motion.div>
                        <motion.div initial={slideAnimation('up').initial} whileInView={slideAnimation('up').whileInView} exit={slideAnimation('up').exit} transition={{delay:0.8}} >CREATIVE</motion.div>
                    </span>
                </div>
            </div>
        </motion.section>
    )
}

export default IAM_Creative