import React, { useState } from 'react';
import { delay, motion } from 'framer-motion';
import { slideAnimation,springAnimation } from '../util/JsAnimations';
import Pointer from '../components/Pointer/Pointer';
import ShopItem from '../components/ShopItem';
import './../style/Shop.css';
import Formatting from '../util/Formatting';

export default function Shop() {

    const formatter = new Formatting();
    const [price, setPrice] = useState('...');

    const handleProductClick = (dataPrice)=>{
        setPrice("");
        delay(()=>setPrice(<motion.div initial={springAnimation.initial} animate={springAnimation.whileInView} whileInView={springAnimation.whileInView} exit={springAnimation.exit}> ZAR {formatter.formatNumber(dataPrice,true)}</motion.div>),200);
    };

    return (
        <motion.section id='shop'>
            <div id="shop_top">
                <motion.div id="partnered_heading" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>Partnered with Fiverr.</motion.div>
                <motion.div id="partnered_text" initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}>A shopping experience, out of this world!</motion.div>
            </div>

            <div id="shop_content">
                <div id="content_left">
                    <div id="content_price"><strong>Starting</strong> From<strong>{price}</strong></div>
                    <div id="content_shop-cta">
                        <motion.a href='https://www.fiverr.com/shekharmaharaj/create-your-website-based-on-your-unique-story' initial={springAnimation.initial} whileInView={springAnimation.whileInView} exit={springAnimation.exit }>
                            <div className="background"></div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-bag-heart" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0M14 14V5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1M8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132"/>
                            </svg>
                            <span>SHOP NOW</span>
                        </motion.a>
                    </div>
                </div>
                <div id="content_right">
                    <ShopItem motion={motion} animation={slideAnimation("right")} title={"Cheap & Quick"} type={"Basic"} delivery={7} outcome={"Figma Design Only"} price={2208.00} color="white" font_style={'normal'} onclick={handleProductClick} />
                    <ShopItem motion={motion} animation={slideAnimation("down")} title={"Something Cool"} type={"Standard"} delivery={30} outcome={"Functional Website"} price={10749.00} color="orange" font_style={'bold'} onclick={handleProductClick} />
                    <ShopItem motion={motion} animation={slideAnimation("down")} title={"Story Teller"} type={"Premium"} delivery={45} outcome={"Awesome Website"} price={19195.00} color="red" font_style={'epic'} onclick={handleProductClick} />
                </div>
            </div>

            <div id="shop_bottom">
                <motion.div id="contact_cta" initial={springAnimation.initial} whileInView={springAnimation.whileInView} exit={springAnimation.exit}><strong>OR</strong> contact me directly</motion.div>
                <Pointer anchor={'contact'} />
            </div>
        </motion.section>
    )
}