import React from 'react';
import './../style/IAM_Educated.css';
import { motion } from 'framer-motion';
import { slideAnimation,springAnimation} from './../util/JsAnimations';

function Education() {
    return (
        <section id="IAM_Educated">
            <div id="IAM_Educated_text">
                <motion.div initial={slideAnimation("down").initial} whileInView={slideAnimation("down").whileInView} >I AM</motion.div>
                <motion.div initial={slideAnimation("right").initial} whileInView={{...slideAnimation("right").whileInView,delay:0.5}} transition={{delay:0.5}}>EDUCATED</motion.div>
            </div>
        </section>
    )
}

export default Education