import React from 'react';
import { motion } from 'framer-motion';
import { slideAnimation,springAnimation} from './../util/JsAnimations';
import './../style/About.css';
import PersonalityTrait from '../components/PersonalityTrait/PersonalityTrait';

const About = () => {
  return (
    <section id='about'>
      <div id="left">
        <motion.img id='left_profile' src="/assets/mr_grey_ShekharMaharaj 1.png" alt="Shekhar 'Sheko' Maharaj - Mr Grey" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}/>
        <motion.h1 id='left_name' initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>Shekhar Maharaj</motion.h1>
        <motion.h2 id='left_occupation' initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}><em>Software Engineer</em></motion.h2>
        <motion.div id="left_descriptors" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>
          <ul>
            <li>Male</li>
            <li>5'8"</li>
            <li>Brown Eyes</li>
            <li>Black Hair</li>
            <li>Extremely Handsome</li>
          </ul>
        </motion.div>
        <motion.div id='left_description' initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>
          Specializing in web, mobile and desktop development; with various tools and technologies under my belt.
        </motion.div>
      </div>
      <div id="right">
        <motion.h2 id='right_intro'>
          Who Am I, Really?
        </motion.h2>
        <motion.div id='right_about'>
          <motion.p initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}>I'm Shekhar Maharaj!</motion.p>
          <motion.p initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}>
            There came a time when I thought to myself: "Hey! I like video games so much that I should learn how to make them!", and I've been in love with both the software development and IT industry ever since.
          </motion.p>
          <motion.p initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}>
            It's something about not just finding a solution but creating one that keeps me coming back for more! I love thinking critically, creatively, and logically. Another thing I love about software development/engineering is the constant need to adapt and learn. It allows me to grow not only as a professional but as a person too!
          </motion.p>
          <motion.p initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}>
            Believe it or not, there isn't much that can keep me away from constantly improving on my tasks and skill sets. The few exceptions are gaming, golf, and all things performing arts!
          </motion.p>

          <motion.p initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}>
            I like my food fast, my cars faster and runtime speeds even faster!
          </motion.p>
        </motion.div>
        <div id="right_personalityTraits">
          <PersonalityTrait delay={0} text="funny" />
          <PersonalityTrait delay={0.1} text="very funny" />
          <PersonalityTrait other={{title:"That's a joke!"}} delay={0.2} text="extremely humble" />
          <PersonalityTrait delay={0.3} text="skilled" />
          <PersonalityTrait delay={0.4} text="great team player" />
          <PersonalityTrait delay={0.5} text="result orientated" />
          <PersonalityTrait delay={0.6} text="goal driven" />
          <PersonalityTrait delay={0.7} text="fast learner" />
          <PersonalityTrait delay={0.8} text="growth mindset" />
          <PersonalityTrait delay={0.9} text="limitless potential" />
          <PersonalityTrait delay={0.10} text="great hair" />
          <PersonalityTrait delay={0.11} text="leadership skills" />
        </div>
      </div>
    </section>
  )
}

export default About