import React from 'react';
import './../style/WhatIDo.css';
import Pointer from '../components/Pointer/Pointer';
import {motion} from 'framer-motion';
import { slideAnimation } from '../util/JsAnimations';

function WhatIDo() {
    return (
        <section id="what-i-do">
            <div id="what-i-do_content">
                <motion.h4 initial={slideAnimation("down").initial} whileInView={slideAnimation("down").whileInView} exit={slideAnimation("down").exit}>What is it that I do?</motion.h4>
                <motion.h3 initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>I TELL STORIES</motion.h3>
                <motion.p initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}>
                    Through the portals of web, mobile and desktop applications, I tell the stories of my clients. Each element is placed with care and consideration to what YOU represent, and what YOU want to be.
                </motion.p>
            </div>
            <Pointer anchor="skills" />
        </section>
    )
}

export default WhatIDo