import React, { useState } from 'react';
import './ExperienceCard.css';
import { motion } from 'framer-motion';
import { slideAnimation,springAnimation} from './../../util/JsAnimations';

const ExperienceCard = (props) => {

    if(props.direction == undefined) throw new Error("ExperienceCard: direction prop is required");
    if(props.duration == undefined) throw new Error("ExperienceCard: duration prop is required");
    if(props.company == undefined) throw new Error("ExperienceCard: company prop is required");
    if(props.role == undefined) throw new Error("ExperienceCard: role prop is required");
    if(props.location == undefined) throw new Error("ExperienceCard: location prop is required");

    const [enableBtn, setEnableBtn] = useState(false);

    setTimeout(() => {
        setEnableBtn(true);
    }, 2000);

  return (
    <div className="experience_card">
        <motion.h2 className="experience_card--time" initial={slideAnimation(props.direction).initial} whileInView={slideAnimation(props.direction).whileInView} exit={slideAnimation(props.direction).exit}>
            {props.duration}
        </motion.h2>
        <motion.div className="experience_card--line" initial={slideAnimation("left").initial} whileInView={{...slideAnimation("left").whileInView}} exit={slideAnimation("left").exit}></motion.div>
        <motion.div className="experience_card--company" initial={slideAnimation(props.direction).initial} whileInView={slideAnimation(props.direction).whileInView} exit={slideAnimation(props.direction).exit}>
            {props.company}
        </motion.div>
        <motion.div className="experience_card--role" initial={slideAnimation(props.direction).initial} whileInView={slideAnimation(props.direction).whileInView} exit={slideAnimation(props.direction).exit}>
            {props.role}
        </motion.div>
        <motion.button title={enableBtn?"Click Me":"Please wait..."} className="experience_card--view" initial={slideAnimation("down").initial} whileInView={slideAnimation("down").whileInView} exit={slideAnimation("down").exit} data-duration={props.duration} data-company={props.company} data-role={props.role} data-location={props.location} onClick={enableBtn ? props.btnClick:()=>console.log("Please wait...")}>
            View
        </motion.button>

    </div>
  )
}

export default ExperienceCard