import React from 'react';
import { motion } from 'framer-motion';
import { slideAnimation,springAnimation} from './../util/JsAnimations';

function Education_Story(props) {
    
    if(!props.institute) throw new Error("Education_Story: institute is a required prop");
    if(!props.degree) throw new Error("Education_Story: degree is a required prop");
    if(!props.minor) throw new Error("Education_Story: minor is a required prop");
    if(!props.date) throw new Error("Education_Story: date is a required prop");
    if(!props.description) throw new Error("Education_Story: description is a required prop");
    if(!props.goBack) throw new Error("Education_Story: goBack is a required prop");

    let img = "";
    let badgeID = "";
    let gpa = "GPA N/A";
    let hasAcademicRecord = false;
    let academicRecordPath = "";

    if(props.institute == "Richfield"){
        img = "/assets/RGIT.png";
        badgeID = "badge_rgit";
        gpa = "GPA 5.0";
        hasAcademicRecord = true;
        academicRecordPath = "/assets/education/RGIT_Academic_Record.pdf";
    }else{
        img = "/assets/VC.png";
        badgeID = "badge_vc";
    }

    const handleAcademicRecordClick = () => {
        if(hasAcademicRecord) {
            window.open(academicRecordPath, "_blank");
        }else{
            alert("No academic record available for this institute.");
        }
    }

    return (
        <div id="story">
            <div id="story_back">
                <motion.div onClick={props.goBack} initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} >
                    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill='black' width='25' height='25'><title/><g data-name="Layer 2" id="Layer_2"><path class="cls-1" d="M15.12,15.53,25,5.66a1,1,0,0,1,1.41,1.41l-9.06,9.06,8.8,8.8a1,1,0,0,1,0,1.41h0a1,1,0,0,1-1.42,0l-9.61-9.61A.85.85,0,0,1,15.12,15.53Z"/><path class="cls-1" d="M5.54,15.53l9.88-9.87a1,1,0,1,1,1.41,1.41L7.77,16.13l8.8,8.8a1,1,0,0,1,0,1.41h0a1,1,0,0,1-1.41,0L5.54,16.73A.85.85,0,0,1,5.54,15.53Z"/></g></svg>
                    Back
                </motion.div>
            </div>
            <motion.div id="story_top" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} >
                <div id="story_top--left">
                    <div className="badge" id={badgeID}>
                        <motion.img src={img} alt={img} initial={slideAnimation("down").initial} whileInView={slideAnimation("down").whileInView} exit={slideAnimation("down").exit} />
                        <motion.div id="adademicRecord" style={{cursor: hasAcademicRecord?"pointer":"not-allowed"}} onClick={handleAcademicRecordClick}
                            initial={{x: -100, y: 140, opacity: 0}}
                            whileInView={{x:-10, y:140, opacity: 1}}
                            transition={{type:"slide", stiffness: 100, damping: 10}}
                        >
                            Academic Record
                        </motion.div>
                    </div>
                </div>
                <div id="story_top--right" style={{color: props.institute == "Richfield"?"#0A2650":"#0C3F57"}}>
                    <div className="educationDetails--top">
                        <motion.div className="educationDetails--top_instiute" initial={slideAnimation("down").initial} whileInView={slideAnimation("down").whileInView} exit={slideAnimation("down").exit} >{props.institute}</motion.div>
                        <motion.div className="educationDetails--top_program" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} >{props.degree}</motion.div>
                    </div>

                    <div className="educationDetails--bottom">
                        <motion.div className="educationDetails--bottom_year" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>{props.date}</motion.div>
                        <motion.div className="educationDetails--bottom_gpa" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>{gpa}</motion.div>
                        <motion.div className="educationDetails--bottom_minor" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>Minor: {props.minor}</motion.div>
                    </div>
                </div>
            </motion.div>
            <motion.div id="story_info" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>
                {props.description}
            </motion.div>
        </div>
    )
}

export default Education_Story