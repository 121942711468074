import { useState, useRef } from 'react';

const useDragScroll = () => {
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const ref = useRef(null);

  const handleMouseDown = (e) => {
    setIsDown(true);
    const element = ref.current;
    setStartX(e.pageX - element.offsetLeft);
    setScrollLeft(element.scrollLeft);
  };

  const handleMouseLeaveOrUp = () => {
    setIsDown(false);
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const element = ref.current;
    const x = e.pageX - element.offsetLeft;
    const walk = (x - startX) * 2; // Scroll speed
    element.scrollLeft = scrollLeft - walk;
  };
  

  const handleTouchStart = (e) => {
    setIsDown(true);
    const element = ref.current;
    setStartX(e.touches[0].pageX - element.offsetLeft);
    setScrollLeft(element.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const element = ref.current;
    const x = e.touches[0].pageX - element.offsetLeft;
    const walk = (x - startX) * 2; // Scroll speed
    element.scrollLeft = scrollLeft - walk;
  };

  const moveLeft = (amount) => {
    const element = ref.current;
    if (element) {
      element.scrollLeft -= amount;
    }
  };

  const moveRight = (amount) => {
    const element = ref.current;
    if (element) {
      element.scrollLeft += amount;
    }
  };

  return {
    ref,
    handleMouseDown,
    handleMouseLeaveOrUp,
    handleMouseMove,
    handleTouchStart,
    handleTouchMove,
    isDown,
    moveLeft,
    moveRight
  };
};

export default useDragScroll;
