import React from 'react';
import './../style/ThingsIDo.css'
import {motion} from 'framer-motion'
import { slideAnimation } from '../util/JsAnimations';

function ThingsIDo() {
    return (
        <section id="things-i-do">
            <motion.div id="tid_overlay" initial={slideAnimation('left').initial} whileInView={slideAnimation('left').whileInView} exit={slideAnimation('left').exit}>
                <div id="tid_overlay--top">
                    <motion.h4 initial={slideAnimation('left').initial} whileInView={slideAnimation('left').whileInView} exit={slideAnimation('left').exit}>The Things I Do...</motion.h4>
                </div>
                <div id="tid_overlay--content">
                    <motion.p initial={slideAnimation('left').initial} whileInView={slideAnimation('left').whileInView} exit={slideAnimation('left').exit}>
                        Over the years that I have spent in my journey becoming a software engineer and being a software engineer, I have planned, developed, designed and signed off many projects with many satisfied customers. Part of my journey to create such beautiful results include cost planning, time estimation, market research, SEO optimization and tool selection with each project being handled with consideration to the customers current and future goals.
                    </motion.p>
                </div>
                <div id="tid_overlay--bottom">
                    <motion.a href="https://github.com/ShekoG1?tab=repositories"target='_blank' initial={slideAnimation('left').initial} whileInView={slideAnimation('left').whileInView} exit={slideAnimation('left').exit}>View Projects</motion.a>
                </div>
            </motion.div>
        </section>
    )
}

export default ThingsIDo