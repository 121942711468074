import React from 'react';
import './../style/Hero.css';
import NavButton from './../components/NavButton/NavButton';
import Pointer from '../components/Pointer/Pointer';
import { motion } from 'framer-motion';
import { slideAnimation, transition } from './../util/JsAnimations';

const Hero = () => {

  const techStackHover = {
    transition: {
      duration: 0.5
    },
    whileHover: {
      paddingLeft: 10
    }
  }

  return (
    <section id="hero">
      <motion.div id="hero_socials" initial={slideAnimation('up').initial} whileInView={slideAnimation('up').whileInView} exit={slideAnimation('up').exit}>
        <NavButton name="LinkedIn" url="https://www.linkedin.com/in/the-shekhar-maharaj/" />
        <NavButton name="GitHub" url="https://github.com/ShekoG1" />
        <NavButton name="Actor" url="https://actor.theshekharmaharaj.com" />
      </motion.div>
      <div id="hero_content">
        <div id="hero_content--top">
          <motion.div id="hero_content--lotus" initial={slideAnimation('left').initial} whileInView={slideAnimation('left').whileInView} transition={{duration:1.5}} exit={slideAnimation('left').exit} whileHover={{rotateY:360}}>
            <img src="/assets/lotus.png" alt="good luck" />
          </motion.div>
          <motion.h1 id="hero_content--name" initial={slideAnimation('left').initial} whileInView={slideAnimation('left').whileInView} exit={slideAnimation('left').exit} whileHover={{x:30}}>
            Shekhar Maharaj
          </motion.h1>
          <motion.h2 id="hero_content--occupation" initial={slideAnimation('left').initial} whileInView={slideAnimation('left').whileInView} transition={{duration:1.5}} exit={slideAnimation('left').exit} whileHover={{rotateX:360}}>
            Software Engineer
          </motion.h2>
        </div>
        <div id="hero_content--bottom">
          <div id="techstack">
            <motion.div id="techstack--web" initial={slideAnimation('up').initial} whileInView={slideAnimation('up').whileInView} exit={slideAnimation('up').exit} {...techStackHover} >Web.</motion.div>
            <motion.div id="techstack--android" initial={slideAnimation('up').initial} whileInView={slideAnimation('up').whileInView} exit={slideAnimation('up').exit} {...techStackHover} >Android.</motion.div>
            <motion.div id="techstack--desktop" initial={slideAnimation('up').initial} whileInView={slideAnimation('up').whileInView} exit={slideAnimation('up').exit} {...techStackHover} >Desktop</motion.div>
          </div>
        </div>
      </div>
      <Pointer anchor="about" />
    </section>
  )
}

export default Hero