import React from 'react';
import './../style/WorthIt.css'
import {motion} from 'framer-motion';
import { slideAnimation } from '../util/JsAnimations';
import Pointer from '../components/Pointer/Pointer';

function WorthIt() {
    return (
        <section id="worth-it">
            <div id="worth-it_top">
                <div id="worth-it_left">
                    <motion.div id="worth-it_left--container" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>
                        <div>The outcome is</div>
                        <div>Worth It</div>
                    </motion.div>
                </div>
                <div id="worth-it_right">
                    <motion.div id="worth-it_right--container" initial={slideAnimation("up").initial} whileInView={slideAnimation("up").whileInView} exit={slideAnimation("up").exit}>
                        <motion.span initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} transition={{delay:0.5}}>Your story is</motion.span>
                        <motion.span initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} transition={{delay:0.5}}>Worth It</motion.span>
                    </motion.div>
                </div>
            </div>

            <motion.div id="recommend-services_anchor" initial={slideAnimation("down").initial} whileInView={slideAnimation("down").whileInView} exit={slideAnimation("down").exit}>
                <span>People recommend my service</span>
                <Pointer anchor="recommendations" />
            </motion.div>
        </section>
    )
}

export default WorthIt