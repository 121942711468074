import React, { useEffect, useState } from 'react';
import './../style/Contact.css';
import {motion, transform} from 'framer-motion';
import { slideAnimation, springAnimation } from '../util/JsAnimations';

function Contact() {

    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setemail] = useState('');
    const [message, setmessage] = useState('');

    const [emailTemplate,setEmailTemplate] = useState('');
    const [highlightPosition, setHighlightPosition] = useState({x:0});
    
    const onTabChange = (e) => {

        let formType = e.target.dataset.type;

        switch(formType) {
            case 'general':
                setfirstName('');
                setlastName('');
                setemail('');
                setmessage('');
                setHighlightPosition({x:0});
                break;
            case 'resume':
                setfirstName('');
                setlastName('');
                setemail('');
                setmessage("I'm interested in discussing potential job opportunities. Could you please send me a copy of your resume?");
                setHighlightPosition({x:33.33});
                break;
            case 'website':
                setfirstName('');
                setlastName('');
                setemail('');
                setmessage("Hello there Shekhar, I hope this email finds you well. I would like to work with you to build my awesome, custom, website. The details are as follows...");
                setHighlightPosition({x:66.66});
                break;
        }
    }

    const onSubmit = (e) => {

        // Write validation to ensure all fields have values before sending the link
        if (!firstName || !lastName || !email || !message) {
            alert("Please fill in all fields before sending the message.")
            return
        }
        
        e.preventDefault();
        const emailTemplate = `mailto:shekharmaharaj2905@gmail.com?subject=Contact from ${firstName} ${lastName}&body=Hi Shekhar,%0D%0A%0D%0A${encodeURIComponent(message)}%0D%0A%0D%0ARegards,%0D%0A${firstName} ${lastName}%0D%0A${email}`;
        window.location.href = emailTemplate;
    }
    const resetForm = ()=>{
        setfirstName('');
        setlastName('');
        setemail('');
        setmessage('');
        setHighlightPosition({x:0});
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'module';
        script.src = 'https://w.behold.so/widget.js';
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    const copyTextToKeyboard = (text) => {
        // navigator.clipboard.writeText(text)
    };

    return (

        <section id="contact">
            <div id="contact_top">
                <div id="top_left">
                    <motion.img src="/assets/contact.png" alt="" initial={springAnimation.initial} whileInView={springAnimation.whileInView} animate={springAnimation.whileInView} />
                </div>
                <div id="top_right">
                    <motion.h4 initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}>Let's get in touch</motion.h4>

                    <div id="contact_details">
                        <motion.div className="contact_group" initial={()=>slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>
                            <label htmlFor="email">Email:</label>
                            <motion.span whileHover={{marginLeft:30,cursor:'copy'}} onClick={copyTextToKeyboard("shekharmaharaj2905@gmail.com")} id="email">shekharmaharaj2905@gmail.com</motion.span>                        </motion.div>
                        <motion.div className="contact_group" initial={()=>slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>
                            <label htmlFor="email">Alternate Email:</label>
                            <motion.span whileHover={{marginLeft:30,cursor:'copy'}} onClick={copyTextToKeyboard("hello@theshekharmaharaj.com")} id="email">hello@theshekharmaharaj.com</motion.span>
                        </motion.div>
                        <motion.div className="contact_group" initial={()=>slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>
                            <label htmlFor="email">Mobile:</label>
                            <motion.span whileHover={{marginLeft:30,cursor:'copy'}} onClick={copyTextToKeyboard("+27-84-894-7990")} id="email">+27-84-894-7990</motion.span>
                        </motion.div>
                    </div>
                </div>
            </div>
            {/* 
            -- REQUIRES NODEJS API --
            <div id="youtube-link">
                <span>Latest on YouTube</span>
                <div id="video-list">
                    <iframe src="https://www.youtube.com/playlist?list=PLJcokwPoqAG3tzIUc2w7Nq5lDXitRyjBa" frameborder="0"></iframe>
                </div>
            </div>
            */}

            <motion.div id="contact_view" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>
                <div id='instagram-link'>
                    <span>Catch me on Instagram</span>
                    <behold-widget feed-id="fZ7bpCd1TSlpMyGqcnTJ"></behold-widget>
                </div>
            </motion.div>

            <div id="contact_form">
                <div id="contact_form_container">
                    <div id="contact_form_tabs">
                        <motion.div id="contact_form_tabs--buttons" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>
                            <motion.span onClick={onTabChange} data-type="general" initial={springAnimation.initial} whileInView={springAnimation.whileInView} animate={springAnimation.whileInView} >General</motion.span>
                            <motion.span onClick={onTabChange} data-type="resume" initial={springAnimation.initial} whileInView={springAnimation.whileInView} animate={springAnimation.whileInView} >Resume Request</motion.span>
                            <motion.span onClick={onTabChange} data-type="website" initial={springAnimation.initial} whileInView={springAnimation.whileInView} animate={springAnimation.whileInView} >I Want A Website</motion.span>
                        </motion.div>
                        <div id="contact_form_tabs--highlight">
                            <div style={{marginLeft:`${highlightPosition.x}%`,transition:'all 1s'}}></div>
                        </div>
                    </div>
                    <div id="contact_form_input-fields">
                        <motion.div class="form-input" id='fname-group' initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>
                            <label for="first-name">First Name</label>
                            <input onChange={(event)=>setfirstName(event.target.value)} value={firstName} type="text" placeholder='John' name="first-name" id="first-name" />
                        </motion.div>
                        <motion.div class="form-input" id='lname-group' initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}>
                            <label for="last-name">Last Name</label>
                            <input onChange={(event)=>setlastName(event.target.value)} value={lastName} type="text" placeholder='Doe' name="last-name" id="last-name" />
                        </motion.div>
                        <motion.div class="form-input" id='email-group' initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>
                            <label for="email-address">Email Address</label>
                            <input onChange={(event)=>setemail(event.target.value)} value={email} type="email" placeholder='john.doe@gmail.com' name="email-address" id="email-address" />
                        </motion.div>
                        <motion.div class="form-input" id='message-group' initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}>
                            <label for="user-message">Message</label>
                            <textarea onChange={(event)=>setmessage(event.target.value)} value={message} name="user-message" placeholder='My super-awesome message to Shekhar...' id="user-message"></textarea>
                        </motion.div>
                        <motion.input title="Send using you default email app. If none is set, an email will not go through." initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} type="button" class="form_submit" onClick={onSubmit} value="Send" id="send-form" />
                        <motion.input initial={slideAnimation("up").initial} whileInView={slideAnimation("up").whileInView} exit={slideAnimation("up").exit} type="button" class="form_reset" onClick={resetForm} value="Reset" id="reset-form" />
                    </div> 
                </div>
                <div id="contact_form-socials">
                    <a href="https://www.linkedin.com/in/the-shekhar-maharaj/" title='LinkedIn'>
                        <motion.img  initial={slideAnimation("up").initial} whileInView={slideAnimation("up").whileInView} exit={slideAnimation("up").exit} transition={{delay:0.01}} src="/assets/icons/linkedin.svg" alt="LinkedIn" />
                    </a>
                    <a href="https://github.com/ShekoG1" title='GitHub'>
                        <motion.img  initial={slideAnimation("up").initial} whileInView={slideAnimation("up").whileInView} exit={slideAnimation("up").exit} transition={{delay:0.03}} src="/assets/icons/github.png" alt="GitHub" />
                    </a>
                    <a href="https://www.instagram.com/sheko_maharaj/" title='Instagram'>
                        <motion.img  initial={slideAnimation("up").initial} whileInView={slideAnimation("up").whileInView} exit={slideAnimation("up").exit} transition={{delay:0.06}} src="/assets/icons/instagram.svg" alt="Instagram" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCKAqf-_PCAEHS8GVSfjiiHA" title='YouTube'>
                        <motion.img  initial={slideAnimation("up").initial} whileInView={slideAnimation("up").whileInView} exit={slideAnimation("up").exit} transition={{delay:0.09}} src="/assets/icons/youtube.svg" alt="YouTube" />
                    </a>
                    <a href="https://www.threads.net/@sheko_maharaj?hl=en" title='Threads'>
                        <motion.img  initial={slideAnimation("up").initial} whileInView={slideAnimation("up").whileInView} exit={slideAnimation("up").exit} transition={{delay:0.12}} src="/assets/icons/threads.svg" alt="Threads" />
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Contact;
