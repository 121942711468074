import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { slideAnimation,springAnimation} from './../util/JsAnimations';

function Education_Overview(props) {

    const [isEnabled, setEnabled] = useState(false);

    setTimeout(()=>{
        setEnabled(true);
    }, 10000)

    return (
        <div id='overview'>
            <div id="top">
                <motion.div id="top_img" initial={slideAnimation("down").initial} whileInView={slideAnimation("down").whileInView} exit={slideAnimation("down").exit}>
                    <img src="/assets/education_success.png" alt="I am educated" />
                </motion.div>
                <div id="top_journey">
                    <motion.p initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}>
                        I first began my higher education journey in <strong>2020</strong>, through the <strong>IIE's Varsity College Durban North</strong>, where I studied <em>computer science majoring in application development</em>. Due to financial reasons, I had to leave VCDN <u>after a precious year of study</u>.
                    </motion.p>

                    <motion.p initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit} >
                        I then immediately jumped into a <strong>Bachelor's Degree in Information Technology</strong> through <strong>Richfield</strong>, where <u>I graduated</u> with an <em>81.58% average and 12 distinctions</em>.
                    </motion.p>
                </div>
            </div>
            <div id="bottom">
                <motion.div className="education" id="vc" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} >
                    <div className="education_content">
                        <motion.div className="education_logo" initial={slideAnimation("down").initial} whileInView={slideAnimation("down").whileInView} exit={slideAnimation("down").exit} >
                            <img src="/assets/VC.png" alt="Varsity College Durban North" />
                        </motion.div>
                        <motion.div className="education_title" initial={slideAnimation("down").initial} whileInView={slideAnimation("down").whileInView} exit={slideAnimation("down").exit} >
                            <span>Bachelors Degree in Computer Science</span>
                        </motion.div>
                    </div>
                    <div className="education_tools">
                        <div>
                            <motion.span className='education_tools--yearCompleted' title='Year completed' initial={slideAnimation("down").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} >2020</motion.span>
                            <motion.a href="https://www.varsitycollege.co.za/" target="_blank" rel="noopener noreferrer" title='Visit their website' initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} t>
                                | Website
                            </motion.a>
                        </div>
                        <motion.div className="education_tools--view" onClick={ props.handleEducationClick} data-institute="Varsity College Durban North" data-degree="Bachelors Degree in Computer Science" data-minor="Computer Science" data-date="2020 - 2020" initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit} >View Details</motion.div>
                    </div>
                </motion.div>
                
                <motion.div className="education" id="rgit" initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit} >
                    <div className="education_content">
                        <motion.div className="education_logo" initial={slideAnimation("down").initial} whileInView={slideAnimation("down").whileInView} exit={slideAnimation("down").exit} >
                            <img src="/assets/RGIT.png" alt="Richfield" />
                        </motion.div>
                        <motion.div className="education_title" initial={slideAnimation("down").initial} whileInView={slideAnimation("down").whileInView} exit={slideAnimation("down").exit} >
                            <span>Bachelors Degree in Information Technology</span>
                        </motion.div>
                    </div>
                    <div className="education_tools">
                        <div>
                            <motion.span className='education_tools--yearCompleted' title='Year completed'  initial={slideAnimation("down").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} >2023</motion.span>
                            <motion.a href="https://www.richfield.ac.za/" target="_blank" rel="noopener noreferrer" title='Visit their website' initial={slideAnimation("down").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit} >
                                | Website
                            </motion.a>
                        </div>
                        <motion.div className="education_tools--view" onClick={ props.handleEducationClick} data-institute="Richfield" data-degree="Bachelors Degree in Information Technology" data-minor="Systems Engineering" data-date="2021 - 2023" initial={slideAnimation("right").initial} whileInView={slideAnimation("right").whileInView} exit={slideAnimation("right").exit}>View Details</motion.div>
                    </div>
                </motion.div>

            </div>
        </div>
    )
}

export default Education_Overview