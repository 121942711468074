import React from 'react';
import './index.css';
import {motion} from 'framer-motion';
import { slideAnimation } from '../../util/JsAnimations';

function Recommendation(props) {

    if (props.item == undefined) throw new Error("Recommendation item cannotbe undefined.");

    const slideUp = slideAnimation("up");
    const item = props.item;
    if (item.name == undefined) throw new Error("Item name cannot be undefined");
    if (item.surname == undefined) throw new Error("Item surname cannot be undefined");
    if (item.recommendation == undefined) throw new Error("Item recommendation cannot be undefined");
    if (item.relation == undefined) throw new Error("Item relation cannot be undefined");

    return (
        <motion.div className="recommendation" initial={slideUp.initial} animate={slideUp.animate} whileInView={slideUp.whileInView} exit={slideUp.exit}>
            <div className="recommendation_top">
                <div className="recommendation--icon">
                    <img src="/assets/user.png" alt="" />
                </div>
                <div className="recommendation_user">
                    <div className="recommendation_user--name" title={`${item.name} ${item.surname}`}>{item.name} {item.surname}</div>
                    <div className="recommendation_user--relationship" title={item.relation}>
                        {item.relation}
                    </div>
                </div>
            </div>
            <div className="recommendation_content">
                {item.recommendation}
            </div>
            <div className="recommendation_footer">
                <a href="#" className="recommendation_find-on--linkedin">
                    <img src="/assets/icons/linkedin.svg" alt="" />
                </a>
            </div>
        </motion.div>
    )
}

export default Recommendation