import React from 'react';
import './../style/experience.css';
import ExperienceCard from './../components/ExperienceCard/ExperienceCard';
import { motion } from 'framer-motion';
import { slideAnimation,springAnimation} from './../util/JsAnimations';

function Experience_Overview(props) {

    if(props.handleExperienceClick == undefined) throw new Error("Experience_Overview: handleExperienceClick prop is required");

    return (
        <div id="experience_overview">
            <div id="experience_overlay">
                <div id="IAM_Experienced">
                    <div>
                        <motion.div initial={slideAnimation("down").initial} whileInView={slideAnimation("down").whileInView} exit={slideAnimation("down").exit}>I AM</motion.div> <br/>
                        <motion.div id="IAM_Experienced--experience-play" initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>
                            <span>E</span>
                            <span>X</span>
                            <span>P</span>
                            <span>E</span>
                            <span>R</span>
                            <span>I</span>
                            <span>E</span>
                            <span>N</span>
                            <span>C</span>
                            <span>E</span>
                            <span>D</span>
                        </motion.div>
                    </div>
                </div>
                <div id="IAM_Experienced_Overview">
                    <div id="IAM_Experienced_Overview--description">
                        <motion.p initial={slideAnimation("left").initial} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}>
                        Since 2020, I have been working on my craft by designing, developing and debugging various projects. In October of 2021, I got my very first role as a Full Stack Developer. Since then, I have built countless solutions, created timeless designs and crafted projects that will:
                        </motion.p>
                    </div>
                    <div id="IAM_Experienced_Overview--bullets">
                        {/* DELAY MAY CAUSE BREAKING ERROR */}
                        <motion.div  whileHover={{marginLeft:'50px'}} initial={springAnimation.initial} whileInView={springAnimation.whileInView} exit={springAnimation.exit} transition={{delay:0}}>ROCK.</motion.div>
                        <motion.div  whileHover={{marginLeft:'50px'}} initial={springAnimation.initial} whileInView={springAnimation.whileInView} exit={springAnimation.exit} transition={{delay:0.20}}>YOUR.</motion.div>
                        <motion.div  whileHover={{marginLeft:'50px'}} initial={springAnimation.initial} whileInView={springAnimation.whileInView} exit={springAnimation.exit} transition={{delay:0.40}}>WORLD.</motion.div>
                    </div>
                </div>
                <div id="experience_cards">
                    <ExperienceCard btnClick={props.handleExperienceClick} location={"Durban, KwaZulu Natal, South Africa"} duration={"2021 - 2024"} company={"Duende Digital"} role={"Full Stack Developer"} direction="left" />
                    <ExperienceCard btnClick={props.handleExperienceClick} location={"Remote"} duration={"2023 - CURRENT"} company={"2U"} role={"Learning Specialist"} direction="right" />
                    <ExperienceCard btnClick={props.handleExperienceClick} location={"Remote"} duration={"2024 - CURRENT"} company={"Doxim"} role={"Integration Developer"} direction="up" />
                </div>
            </div>
        </div>
    )
}

export default Experience_Overview