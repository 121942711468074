export default class Formatting{

    formatNumber(number, makePretty=false, decimalSpaces=2) {
        if (number === null || number === undefined) {
            throw new Error("You must enter a number to use this method")
        }
        
        number = parseFloat(number)
        
        if (isNaN(number)) {
            throw new Error("You input is 'Not a Number (NaN)'")
        }
        
        let formattedNumber = number.toFixed(decimalSpaces)
        
        if (makePretty) {
            let parts = formattedNumber.split('.')
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            formattedNumber = parts.join('.')
        }
        
        return formattedNumber
    }
}