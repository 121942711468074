export default function getRecommendation(){
    return [
        {
            "name": "Hennah",
            "surname": "Soorjee",
            "recommendation": <><p>I am thrilled to recommend Shekhar Maharaj, who designed my website and consistently provides exceptional service. Shekhar's technical expertise and prompt support whenever issues arise have been invaluable.</p><p>His attention to detail, creative problem-solving, and friendly demeanor make him a top-notch Full Stack Developer to work with. I highly recommend Shekhar for any web development project; he's a true professional who goes above and beyond to ensure client satisfaction.</p></>,
            "viewOnLinkedIn": false,
            "relation": "Client"
        },
        {
            "name": "Aqeelah",
            "surname": "Rahman",
            "recommendation": <><p>Shekhar has consistently demonstrated exceptional technical expertise in the field of Information Technology. His ability to troubleshoot complex issues, implement innovative solutions, and adapt to rapidly evolving technologies has greatly benefited us in our project.</p><p>Beyond his technical skills, Shekhar is a dedicated and reliable team member. He communicates effectively, collaborates well with his team members, and always exhibits a strong work ethic. His commitment to continuous learning and professional development is evident through his enthusiasm.</p><p>I have no doubt that Shekhar will excel in any IT role he chooses to pursue, and I highly recommend him for any opportunity he seeks. He is an asset to any organization, and I am confident he will continue to make valuable contributions in the field of Information Technology.</p></>,
            "viewOnLinkedIn": false,
            "relation": "Team-mate"
        },
        {
            "name": "Naazia",
            "surname": "Ephraim",
            "recommendation": <><p>I am delighted to recommend Shekhar Maharaj based on our collaborative experience at Richfield Graduate Institute of Technology. Shekhar consistently demonstrated outstanding teamwork and collaboration throughout our project. His ability to contribute ideas, engage with team members, and facilitate effective communication significantly enhanced the overall productivity of our group.</p><p>As a peer, he was always willing to share insights and knowledge, fostering a collaborative environment that promoted creativity and innovation.</p><p>One of Shekhar's remarkable strengths also lies in his professionalism. He approached tasks with a high degree of dedication, meeting deadlines and delivering results with precision. His commitment to excellence was a driving force behind the success of our project, and he demonstrated a rare combination of technical acumen and attention to detail.</p></>,
            "viewOnLinkedIn": false,
            "relation": "Team-mate"
        },
        {
            "name": "Siphamandla",
            "surname": "Ngidi",
            "recommendation": <><p>It is truly a delight to have Shekhar Maharaj as my colleague. Our collaboration is seamless, marked by effective communication and meticulous project planning. While I concentrate on the front-end aspects of certain projects, Shekhar adeptly handles the backend, crafting APIs that seamlessly integrate with the front-end.</p><p>I feel privileged to work alongside Shekhar, and his continuous enthusiasm for learning and embracing new technologies is truly inspiring.</p></>,
            "viewOnLinkedIn": false,
            "relation": "Team-mate"
        },
        {
            "name": "Darshan",
            "surname": "Naicker",
            "recommendation": <><p>I had the pleasure of managing Shekhar Maharaj for 2 and a half years at Duende Digital, where he consistently demonstrated exceptional technical skills and a remarkable work ethic. Shekhar played a crucial role in the development of several key projects, always delivering high-quality code and innovative solutions to complex problems.</p><p>He has demonstrated exceptional ability in quickly adapting to new frameworks, languages, and technologies such as REACT and other Javascript frameworks, SQL databases, Flutter mobile app development, PHP through Codeigniter, and advanced API integration to name only a few, all of which were crucial to the success of our projects.</p><p>What sets Shekhar apart is his ability to quickly grasp new technologies and apply them effectively. His contributions to our team were invaluable, particularly during tight deadlines and challenging situations. Shekhar is also an excellent team player, always willing to lend a hand and share his knowledge with others.</p><p>I highly recommend Shekhar for any software engineering role. His passion for technology, combined with his dedication and problem-solving abilities, make him an outstanding asset to any organisation.</p></>,
            "viewOnLinkedIn": false,
            "relation": "Manager"
        },
        {
            "name": "Jiveshen",
            "surname": "Govender",
            "recommendation": <><p>I am thrilled to share my experience with the website my friend created for me. The design is exceptional, and I am extremely happy with how it looks. The service provided was top-notch, ensuring that all my needs and preferences were met.</p><p>Yes, I got exactly what I was looking for. The website beautifully tells my story and effectively communicates my message. Overall, I couldn't be more satisfied with the outcome.</p></>,
            "viewOnLinkedIn": false,
            "relation": "Client"
        }
    ]
    
}