import React, { useState } from 'react';
import NavButton from '../components/NavButton/NavButton';
import {motion} from 'framer-motion';
import {navAnimation} from './../util/JsAnimations'
import './../style/Nav.css';

const Nav = () => {

  const [showNav, setShowNav] = useState(false);

  return (
    <motion.nav initial={navAnimation.initial} animate={navAnimation.animate} transition={navAnimation.transition}>
      <i className='menu' onClick={() => setShowNav(!showNav)}>
        {/* <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"> */}
        <svg className={showNav ? "open":" "} fill='white' id="burgericon" xmlns="http://www.w3.org/2000/svg" width="50" height="50">
          <g class="icon">
            <rect class="frstbar" x="10" y="10" width="40" height="4" />
            <rect class="scndbar" x="10" y="22" width="40" height="4" />
            <rect class="thrdbar" x="10" y="34" width="40" height="4" />
          </g>
        </svg>
      </i>
      <div id="list-container" className={showNav? "show" : "hide"}>
        <NavButton url={'#about'} animation="up" name="about" />
        <NavButton url={'#education'} animation="up" name="education" />
        <NavButton url={'#experience_overview'} animation="up" name="experience" />
        <NavButton url={'#skills'} animation="up" name="skills" />
        <NavButton url={'https://github.com/ShekoG1?tab=repositories'} animation="up" name="projects" />
        <NavButton url={'#shop'} animation="up" name="shop" />
        <NavButton url={'#contact'} animation="up" name="contact" />
      </div>
    </motion.nav>
  )
}

export default Nav