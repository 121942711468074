import React, { useState } from 'react';
import Experience_Story from '../islands/Experience_Story';
import Experience_Overview from '../islands/Experience_Overview';

function Experience() {
    const [viewStory, setViewStory] = useState(false);
    const [role, setRole] = useState("");
    const [company, setCompany] = useState("");
    const [location, setLocation] = useState("");
    const [duration, setDuration] = useState("");

    const handleExperienceClick = (event) => {
        setRole(event.target.getAttribute("data-role"));
        setCompany(event.target.getAttribute("data-company"));
        setLocation(event.target.getAttribute("data-location"));
        setDuration(event.target.getAttribute("data-duration"));
        
        setViewStory(true);
    }

    return (
        <section id="experience">
            {
                viewStory?
                <Experience_Story goBack={() => setViewStory(false)} role={role} company={company} location={location} duration={duration} />
                :
                <Experience_Overview handleExperienceClick={(event)=>handleExperienceClick(event)} />
            }
        </section>
    )
}

export default Experience