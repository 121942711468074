import React, { useState } from 'react';
import './../style/Recommendations.css';
import getRecommendation from './../data/recommendations';
import Recommendation from './../components/Recommendation';
import useDragScroll from '../util/useDragScroll';
import {motion} from 'framer-motion';
import { slideAnimation } from '../util/JsAnimations';

export default function Recommendations() {
    const recommendations = getRecommendation();
    const dragScroll1 = useDragScroll();

    return(
        <motion.section id='recommendations'>
                <motion.div id="recommendations_pages" initial={slideAnimation("down").initial} animate={slideAnimation("down").animate} whileInView={slideAnimation("down").whileInView} exit={slideAnimation("down").exit}>
                    <span>Click and Drag to View</span>
                </motion.div>
                <div id="recommendations_body">
                    <motion.div id="recommendations_body--content"
                        ref={dragScroll1.ref}
                        onMouseDown={dragScroll1.handleMouseDown}
                        onMouseLeave={dragScroll1.handleMouseLeaveOrUp}
                        onMouseUp={dragScroll1.handleMouseLeaveOrUp}
                        onMouseMove={dragScroll1.handleMouseMove}
                        onTouchStart={dragScroll1.handleTouchStart}
                        onTouchEnd={dragScroll1.handleMouseLeaveOrUp}
                        onTouchMove={dragScroll1.handleTouchMove}
                        initial={slideAnimation("left").initial} animate={slideAnimation("left").animate} whileInView={slideAnimation("left").whileInView} exit={slideAnimation("left").exit}
                    >
                        {
                            // recommendations.map((item, index) => (
                            //     <Recommendation key={index} item={item} />
                            // ))
                        }
                                <Recommendation item={recommendations[0]} />
                                <Recommendation item={recommendations[1]} />
                                <Recommendation item={recommendations[2]} />
                                <Recommendation item={recommendations[3]} />
                                <Recommendation item={recommendations[4]} />
                                <Recommendation item={recommendations[5]} />
                    </motion.div>
                </div>
        </motion.section>
    );
}
