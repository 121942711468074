import React from 'react';
import Recommendations from './Recommendations';
import Shop from './Shop';
import {motion} from 'framer-motion';
import './../style/space.css';

function Stars() {
    return (
        <motion.section id='stars_container'>
            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
            <div id="story_content">
                <Recommendations />
                <Shop />
            </div>
        </motion.section>
    )
}

export default Stars