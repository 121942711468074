import React from 'react';
import './style.css';

function Footer() {
    return (
        <footer>
            <div>© 2024 Shekhar Maharaj. All rights reserved.</div>
            <div>Made with <span>Pure Awesomeness</span> by, the guy who owns this site - <a href="https://theshekharmaharaj.com">Shekhar Maharaj</a></div>
        </footer>
    )
}

export default Footer